// LATAM COMPANIES  can't register to BO events
export const restrictedProfiles = [67106, 67105];
// export const privateEvents = [52,54];
export const privateEvents = [52,53];
// export const publicEvents = [52]; // webinars
export const publicEvents = [51]; // webinars
// export const boEvents = [53] // business opportunities events
export const boEvents = [52] // business opportunities events
export const preRegisterUrl = "https://connect.eventtia.com/en/public/events/masterdatabase/registration/attendees?"
export const participantTypeCategories = {
  // "67097": [52, 53, 54], // company
  "68629": [51, 52, 53], // company
  // "67098": [52, 54], // Stakeholders
  "68621": [51, 53], // LATAM Stakeholders
  "68599": [51, 53], // EU Stakeholders
  // "67105": [52, 54], // LATAM Company: Technology End User
  "68413": [51, 53], // LATAM Company: Technology End User
  // "67106": [52, 54] // LATAM Company: Turnkey Project Engineering Company, Sales Agent
  "68271": [51, 53] // LATAM Company: Turnkey Project Engineering Company, Sales Agent
};

export const canRegister = (eventCategory) => {
  let profileId = localStorage.getItem('profileId');
  let categoryId = parseInt(eventCategory);
  if(publicEvents.includes(categoryId)){
    return true
  }
  if(!profileId && !publicEvents.includes(categoryId)){
    return false
  }
  return participantTypeCategories[profileId].includes(categoryId)
}