import React, {useState} from 'react';
import { useForm } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Button, Modal } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { preRegisterUrl } from "../Variables";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    textAlign: 'center',
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    //maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    //border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
		backgroundColor: '#f9bf25 !important',
		color: 'black',
		height: '40px',
		width: '180px',
		fontWeight: 'bold',
		borderRadius: 'unset'
	}
}));

export default function LoginModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const { register, handleSubmit, errors } = useForm(); // initialize the hook
  const[loginErrors, setLoginErrors] = useState([])
  const onSubmit = (data) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }
    fetch(`https://connect.eventtia.com/en/api/v3/events/${props.eventUri}/portal-login?`, requestOptions)
    // fetch(`http://localhost:3001/en/api/v3/events/${props.eventUri}/portal-login?`, requestOptions)
    .then(async response => {
      const data = await response.json();
      if(!response.ok){
        setLoginErrors(['Invalid credentials']);
      }else{
        localStorage.setItem('token', data.auth_token);
        localStorage.setItem('profile', data.attendee_type);
        localStorage.setItem('profileId', data.attendee_type_id);
        setLoginErrors([]);
        props.onSuccess();
      }
		}).catch(error => {
      console.log(error)
    })
  };
  const preRegisterPath = (
    <a href={preRegisterUrl} target="_blank">here!</a>
  );

  const body = (
    <div style={getModalStyle()} className={classes.paper}>
      <h2 id="simple-modal-title">Login</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {loginErrors.length > 0 ? 
            (<Grid item xs={12}><Alert severity="error">Please check your credentials</Alert></Grid>)
            :(null)
          }
          <Grid item xs={12}>
            <TextField
              id="outlined-helperText"
              label="Email"
              name="email"
              inputRef={register({required: true})}
              error={!!errors.email}
              variant="outlined"
              style={{marginBottom: '20px', width: '100%'}}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-helperText"
              label="Password"
              name="password"
              type="password"
              inputRef={register({required: true})}
              error={!!errors.password}
              variant="outlined"
              style={{marginBottom: '20px', width: '100%'}}
            />
          </Grid>
          <Grid item xs={12}>
            <p>If you are not part of the LCBA community yet, register {preRegisterPath} </p>
            <Button variant="contained" color="secondary" className={classes.button} type="submit">
              Login
            </Button>
          </Grid>
        </Grid>
      </form> 
    </div>
  );
  
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}