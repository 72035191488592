import React from 'react';
import ImageGallery from 'react-image-gallery';
import { withStyles, Typography, Button} from '@material-ui/core';
import "react-image-gallery/styles/css/image-gallery.css";
import { Link } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import Divider from '@material-ui/core/Divider';

import calendar from '../assets/images/ic_date_range_24px.svg';
import schedule from '../assets/images/ic_schedule_24px.svg';
import place from '../assets/images/ic_place_24px.svg';
import rightNav from '../assets/images/right_nav.svg';
import leftNav from '../assets/images/left-nav.svg';
function Carousel(props){
	// https://github.com/alvarotrigo/fullPage.js#fullpagejs
	const events = props.events
	let formatSlideEvents = () => {
		const items = []
		if (events.size <= 0)
			return items
		events.forEach((item, key) =>{
			if (item.hero.large_url !== null && item.category_id != null)
				items.push({original: item.hero.large_url, thumbnail: item.hero.thumbnail_url, description: buildSlideEventInfo(item), originalClass: props.classes.carouselImg, bulletClass: props.classes.bulletClass})
		})
		return items
	}

	let buildSlideEventInfo = (item) => {
		return(
			<div>
			<div className={props.classes.description}>
				<div className='title-container'>
					<Typography variant='h5' className='description-title'>{item.name}</Typography>
				</div>
				<br/>
				<div className={props.classes.detailsContainer}>
					<div><img src={calendar}  alt='' /><b>Fecha</b> {item.start_date.split(' ')[0]}</div>
					<div className= 'date-info' style={{display: 'none'}}><img src={schedule}  alt=''></img><b>Hora</b> {item.start_date.split(' ')[1]}</div>
					<div className='place-info' style={{display: 'none'}}><img src={place}  alt=''></img>{item.address_1} - {item.city}</div>
				</div>
				<br/>
				<div className={props.classes.locationInfo}>
					<LinesEllipsis 
						text={item.description}
						ellipsis='...'
						maxLine='2'
					/>
				</div>
				<br />
				<div className={props.classes.buttonContainer}>
					<Button variant="contained" color="secondary" className={props.classes.button}>
						<Link to={`${item.event_uri}/details`} style={{textDecoration: 'none', color: 'black'}}>
							Ver más
						</Link>
					</Button>
				</div>
			</div>
			<div className={props.classes.carouselFooter}>
				<div className="description-info">
					{item.name}
				</div>
				<Divider orientation="vertical" flexItem />
				<div className="date-info">
					<div>Fecha: {item.start_date.split(' ')[0]}</div>
					<Divider orientation="vertical" />
					<Button variant="contained" color="secondary" className={props.classes.button}>
						<Link to={`${item.event_uri}/details`} style={{textDecoration: 'none', color: 'black'}}>
							Ver más
						</Link>
					</Button>
				</div>
			</div>
			</div>
		)
	}
	return (
		<ImageGallery 
			items={formatSlideEvents()}
			showPlayButton={false}
			showThumbnails={false}
			showFullscreenButton={false}
			showBullets={true}
			additionalClass={props.classes.carousel}
			infinite={true}
		/>
	)
}
const styleSheet = theme => ({
	detailsContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		fontSize: '17px',
		fontFamily: 'Lato, sans-serif',
		fontWeight: '400',
		fontStyle: 'normal',
		'& div': {
			paddingRight: '10px',
		},
		'& div img': {
			width: 'unset !important',
			height: 'unset !important',
			paddingRight: '10px'
		},
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
			fontSize: '14px'
		},
		'& .date-info':  {
			lineHeight: '25px'
		},
		'& .place-info': {
			lineHeight: '25px'
		}
	},
	locationInfo: {
		fontFamily: 'Lato, sans-serif', 
		fontSize: '16px', 
		lineHeight: '24px', 
		fontWeight: '400', 
		width: '60%',
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			fontSize: '14px'
		}
	},
	description: {
		textAlign: 'left', 
		margin: '0 10%',
		padding: '15px',
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			margin: '0 0'
		},
		'& .title-container': {
			'& .description-title': {
				fontWeight: '900', 
				fontStyle: 'normal', 
				fontSize: '30px', 
				fontFamily: 'Lato, sans-serif',
				[theme.breakpoints.down('sm')]: {
					fontSize: '18px',
					margin: '0 0 0 0'
				}
			}
		}
	},
	carouselFooter: {
		backgroundColor: '#efefef', 
		position: 'absolute', 
		bottom: 0, 
		width: '100%', 
		color: 'black', 
		display: 'flex',
		fontFamily: 'Lato, sans-serif',
		//padding: '12px 0',
		height: '60px',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			height: '100px'
		},
		'& .description-info': {
			width: '50%',
			fontWeight: '700', 
			fontFamily: 'Lato, sans-serif',
			fontSize: '15px',
			lineHeight: '1.5'
		},
		'& .date-info': {
			width: '50%',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			borderLeft: '1px solid black',
			padding: '0 30px',
			fontFamily: 'Lato, sans-serif',
			fontWeight: '700',
			[theme.breakpoints.down('sm')]: {
				flexWrap: 'wrap',
				padding: '0 10px'
			},
		}
	},
	carousel: {
		height: '50vh',
		'& .image-gallery-image img': {
			height: '50vh',
		},
		'& .image-gallery-left-nav': {
			left: '89%',
			top: '60%',
			[theme.breakpoints.down('sm')]: {
				left: '72%',
				display: 'none'
			},
			'&:before': {
				content: `url(${leftNav})`
			}
		},
		'& .image-gallery-right-nav': {
			top: '60%',
			right: '2%',
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			},
			'&:before': {
				content: `url(${rightNav})`
			} 
		},
		'& .image-gallery-bullets': {
			display: 'flex',
			justifyContent: 'flex-end',
			alignSelf: 'center',
			left: 'unset',
			position: 'absolute',
			top: '30%',
			width: '10px',
			zIndex: 0,
			'& .image-gallery-bullets-container': {
				display: 'flex',
				flexDirection: 'column',
				'& button': {
					padding: '3px'
				}
			}
		}
	},
	carouselImg: {
		'& .image-gallery-image img': {
			objectFit: 'cover',
		},
		'& .image-gallery-image .image-gallery-description': {
			padding: 0,
			display: 'flex',
			alignItems: 'center',
			position: 'absolute',
			width: '100%',
			height: '100%',
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			top: 0
		},
	},
	bulletClass: {
		margin: '5px 9px !important',
		'&.active': {
			background: '#F9D03F !important',
			border: '1px solid #F9D03F !important'
		}
	},
	buttonContainer: {
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center'
		}
	},
	button: {
		backgroundColor: '#F9D03F !important',
		color: 'black', 
		fontSize: '17px',
		fontFamily: 'Lato, sans-serif',
		fontStyle: 'normal',
		fontWeight: '700',
		borderRadius: 'unset',
		[theme.breakpoints.down('sm')]: {
			width: '70%',
			margin: '20px 0 0 0'
		}
	}
})

export default withStyles(styleSheet)(Carousel);