import React from 'react';
import { withStyles, Typography, Card, 
	CardHeader, CardContent
} from '@material-ui/core';
import "react-image-gallery/styles/css/image-gallery.css";
import defaultImg from '../assets/images/default.svg';
import { Link } from 'react-router-dom';

const EventCard = (props) => {
	const event = props.event
	
	let cardEventHeader = (event) => {
		return(
			<div className={props.classes.media}>
				{headerImg()}
				{(event.category_name !== null && 0 !== 0) ? (
					<div className={props.classes.categoryLabel} ><p>{event.category_name}</p></div>
				) : (
					null
				)}	
			</div>
		)
	}

	let headerImg = () => {
		let url = defaultImg
		let height = 'auto'
		
		if(event.logo.medium_url !== null){
			url = event.logo.medium_url
			height = '140px'
			return <img src={url} alt='img' style={{objectFit: 'cover', height: `${height}`, width: '100%'}} />
		}
		return <div className='event-img-container' style={{display: 'flex', justifyContent: 'center', width:'100%'}}><img src={url} alt='img' style={{objectFit: 'cover', height: `${height}`, width: '70%'}} /></div>
	}

	return (
		<Card className={props.classes.cardContainer}>
			{event.category_name !== null ? (
				<div style={{textAlign: 'left'}}>
					<span className={props.classes.categoryTab}>
						{event.category_name}
					</span>
				</div>
				
			) : (
				null
			)}
			<Link to={`${event.event_uri}/details`} className={props.classes.eventLink}>
				<CardHeader
					component={() => cardEventHeader(event)}
				/>
				<CardContent style={{display: 'flex', alignItems: 'center', padding: 'unset', textAlign: 'left'}}>
					<Typography variant="body2" color="textSecondary" component="div" style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
						<p style={{fontWeight: '700', fontFamily: 'Lato, sans-serif', color: '#3D3D3D', fontStyle: 'normal', fontSize: '15px', margin: '10px 0', width: '100%'}}>{event.name}</p>
						<p style={{margin: 'unset', color: '#3E3E3E'}}><b>Start date:</b> {event.start_date.split(' ')[0]}</p>
						<p style={{margin: 'unset', color: '#3E3E3E'}}><b>End date:</b> {event.end_date.split(' ')[0]}</p>
					</Typography>
				</CardContent>
			</Link>
		</Card>
	)
}

const categoryColor = (categoryID) => {
	const colors = [{id: 16, color: 'rgb(250, 239, 56)'}, {id: 17, color: '#AE6143'}, {id: 18, color: 'rgb(83, 146, 41)'}, {id: 19, color: '#D7D4C2'}, {id: 20, color: 'rgb(231,157,37)'}, {id: 21, color: 'rgb(49, 28, 158)'}, {id: 38, color: 'rgb(211, 35, 95)'}]
	const categoryInfo =  colors.filter(function(info) {
		if(info.id === categoryID){
			return info.color
		}
	})
	if(categoryInfo.length > 0){
		return categoryInfo[0].color
	}

	return '#00bd37'
}

const styleSheet = theme => ({
	cardContainer: {
		boxShadow: 'unset', 
		padding: '15px', 
		marginBottom: '20%',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '5%'
		}
	},
	media: {
		height: '140px',
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#ececec',
		borderBottom: props => ` 5px solid ${categoryColor(props.event.category_id)}`,
		
	},
	eventLink: {
		textDecoration: 'none !important'
	},
	categoryLabel: {
		position: 'absolute',
		top: 0,
		color: 'white',
		borderRadius: '5px',
		height: '20px',
		padding: '0 10px',
		display: 'flex',
		margin: '10px',
		alignItems: 'center',
		//display: 'none',
		backgroundColor: props => `${categoryColor(props.event.category_id)}`,
		'& p': {
			padding: 'unset',
			margin: 'unset',
			textTransform: 'uppercase', 
			fontSize: '10px'
		}
	},
	categoryTab: {
		backgroundColor: props => `${categoryColor(props.event.category_id)}`,
		display: 'inline-flex',
		alignItems: 'center',
		color: 'white',
		height: '30px',
		padding: '0 10px',
		textTransform: 'uppercase',
		fontSize: '10px'
	}
})

export default withStyles(styleSheet)(EventCard);