import React from 'react';
import { withStyles } from '@material-ui/core'; 
// import { Link } from 'react-router-dom';
// import facebook from '../assets/images/facebook.svg';
// import instragram from '../assets/images/instagram.svg';
// import twitter from '../assets/images/twitter.svg';
// import youtube from '../assets/images/youtube-icon.svg';
//import terms from '../assets/files/terms.pdf';
//import privacy from '../assets/files/privacy.pdf';
import logoIdom from '../assets/images/footer-logo.png';
// import vimeo from '../assets/images/Vimeo.svg';
// import snapchat from '../assets/images/Snapchat.svg';
// import linkedin from '../assets/images/LinkedIn.svg';
function Footer(props){
  return(
		<div>
			<div className={props.classes.footerSection1}>
				<div className="idom-logo" ><img src={logoIdom} alt='idom-logo'/></div>
				<div className='contact-section'>
					<div className='contact-title'>Contact</div>
					<div className='contact-info'>
						<p style={{margin: '0px !important'}}>
							Avenida Angelica, 2491<br/>
							7º andar, conjunto 72<br/>
							Consolação – CEP 01227-200<br/>
							São Paulo – SP, Brazil<br/><br/>
							55 (11) 2589-4023<br/>
							latam@lowcarbonbusinessaction.com<br/>	
						</p>	
						<p><a href="https://s3.amazonaws.com/eventtia/event_files/71510/original/terms.pdf?1619108642" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#818181'}}>Terms and conditions</a></p>
						<p><a href="https://s3.amazonaws.com/eventtia/event_files/71512/original/privacy.pdf?1619108753" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#818181'}}>Privacy policy</a></p>
				
					</div>
				</div>
			</div>
		</div>
	)
}

const styleSheet = theme => ({
	footerSection1: {
		backgroundColor: '#EDF0F5', 
		display: 'flex', 
		justifyContent: 'space-between',
		paddingLeft: '10%',
		paddingRight: '10%',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
			justifyContent: 'center'
		},
		alignItems: 'center',
		'& .idom-logo': {
			width: '50%',
			'& img': {
				position: 'relative',
				width: '100%'
			}
		},
		'& .contact-section': {
			'& .contact-title': {
				color: '#00bd37', 
				fontSize: '36px'
			},
			'& .contact-info': {
				color: '#565656',
				fontSize: '16px'
			}
		}
	}
});

export default withStyles(styleSheet)(Footer);