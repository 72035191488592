import React, {useEffect, useState} from 'react';
import { withStyles, Typography, Button,
	Card, CardContent
} from '@material-ui/core';
import "react-multi-carousel/lib/styles.css";
import email from '../../assets/images/ic_mail_outline_24px.svg';
import Footer from '../Footer';
import LoginModal from '../LoginModal';
import EventHeader from '../EventHeader';
import {useHistory} from 'react-router-dom';
import { publicEvents, canRegister, boEvents } from '../../Variables';

function EventDetails(props){
	const[event, setEventInfo] = useState({})
	const[modalOpen, setModalOpen] = useState(false)
	const history = useHistory()
	useEffect(() => {
		loadEventInfo()
	}, [])

	const handleModalOpen = () => {
		if( publicEvents.includes(event.category_id) || localStorage.getItem('token')){
			redirectToRegister()
		}else{
			setModalOpen(true);
		}
	};
	
	const handleModalClose = () => {
    setModalOpen(false);
	};
	
	let loadEventInfo = () => {
		fetch(`https://connect.eventtia.com/en/api/v2/events/search_by_uri/${props.match.params.event_uri}?include_participants=true`)
		// fetch(`http://localhost:3001/en/api/v2/events/search_by_uri/${props.match.params.event_uri}?include_participants=true`)
		.then(results => results.json())
		.then((data) => {
			console.log(data)
			setEventInfo(data)
		})
	}

	let parseDate = (dateString) => {
		const humanDate = new Date(dateString)
		// const humanDate = new Date(dateString.split(' ')[0])
		const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
			'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
			];
		return(
			<div  className={props.classes.dateNumber} >
				<p className={props.classes.number} >{dateString.split(' ')[0].split('-')[2]}</p>
				<p className={props.classes.text} >{monthNames[humanDate.getMonth()]}</p>
			</div>
		)
	}

	let registerUrl = () => {
		const participantToken = localStorage.getItem('token');

	 if(boEvents.includes(event.category_id) && participantToken && !canRegister(event.category_id)){
	 	return(null);
	 }else{
	 	return (
	 		<Button variant="contained" color="secondary" className={props.classes.button} onClick={handleModalOpen}>
	 			APPLY
	 		</Button>
	 	)
	 }
	}
	
	const redirectToRegister = () => {
		history.push(`/${event.event_uri}/register`)
	}

	const categoryColor = (categoryID) => {
		const colors = [{id: 16, color: 'rgb(250, 239, 56)'}, {id: 17, color: '#AE6143'}, {id: 18, color: 'rgb(83, 146, 41)'}, {id: 19, color: '#D7D4C2'}, {id: 20, color: 'rgb(231,157,37)'}, {id: 21, color: 'rgb(49, 28, 158)'}, {id: 38, color: 'rgb(211, 35, 95)'}]
		const categoryInfo =  colors.filter(function(info) {
			if(info.id === categoryID){
				return info.color
			}
		})
		if(categoryInfo.length > 0){
			return categoryInfo[0].color
		}
		return '#00bd37'
	}

  return(
		<div className={props.classes.eventDetails}>
			{Object.keys(event).length !== 0 ? (
				<div>
					<EventHeader
						event={event}
					/>
					<div className='details'>
						<Card className='card-info'>
							<CardContent style={{padding: 'unset'}}>
								<div className={props.classes.detailsBody}>
									<div className='left-side' style={{display: 'flex', alignItems: 'center'}}>{ parseDate(event.start_date)}</div>
									<div className='right-side'>
										<div className='location-info'>
											<p className='font-size-17'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={categoryColor(event.category_id)} viewBox="0 0 20 20">	<path class="a" d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm.5-13H11v6l5.25,3.15L17,14.92l-4.5-2.67Z" transform="translate(-2 -2)"/></svg> { new Date(event.start_date.replace(/ /g,"T")).toLocaleDateString([],{year: 'numeric', month: 'long', day: 'numeric'})}</p>
											<p className='font-size-17'><svg xmlns="http://www.w3.org/2000/svg" width="14.977" height="21.395" fill={categoryColor(event.category_id)} viewBox="0 0 14.977 21.395"><path class="a" d="M12.488,2A7.483,7.483,0,0,0,5,9.488C5,15.1,12.488,23.4,12.488,23.4S19.977,15.1,19.977,9.488A7.483,7.483,0,0,0,12.488,2Zm0,10.163a2.674,2.674,0,1,1,2.674-2.674A2.675,2.675,0,0,1,12.488,12.163Z" transform="translate(-5 -2)"/></svg> {event.address_1}</p>
											{ (new Date(event.end_date.replace(/ /g,"T")) >= new Date()) ? (
												registerUrl()
											) : (
												null
											)}
										</div>
										<div className='description' style={{textAlign: 'left', whiteSpace: 'pre-wrap'}}>
											<Typography variant='h6' style={{fontWeight: '700', fontFamily: 'Lato, sans-serif', fontSize: '20px', margin: '0 0 10px 0'}}>Event description</Typography>
											<Typography variant='body2' style={{fontWeight: '400', fontFamily: 'Lato, sans-serif', fontSize: '15px'}} >{event.description}</Typography>
											<br></br>
											{event.city &&
												<a href={`http://www.google.com/maps/place/${event.coordinates.latitude},${event.coordinates.longitude}`} target='_blank' rel="noopener noreferrer">
													<div className='location-map' style={{backgroundImage: `url(${event.static_map})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '20vh', marginBottom: '16px'}}>
													</div>
												</a>
											}
										</div>
									</div>
								</div>
								<div className='media'></div>
								<div className='files'></div>
							</CardContent>
						</Card>
						<div className='contact' style={{backgroundColor: categoryColor(event.category_id)}} >
							<p style={{fontWeight: 'bold'}}>Contact: {event.sender_name}</p>
							<p><a style={{textDecoration: 'none', color: 'white'}} href={'mailto:' + event.sender_email}><img src={email} alt='em' style={{marginRight: '10px'}}/>{event.sender_email}</a></p>
						</div>
					</div>
				</div>
			): (
				null
			)}
			<LoginModal
				open= {modalOpen}
				close={handleModalClose}
				eventUri={props.match.params.event_uri}
				onSuccess={redirectToRegister}
			/>
			<Footer />
		</div>
	)
}

const styleSheet = theme => ({
	eventDetails: {
		'& .details': {
			margin: '-3% 10% 0',
			'& .card-info': {
				borderRadius: '20px 20px 0 0',
				'& .right-side': {
					width: '100%',
					'& .location-info': {
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						[theme.breakpoints.down('sm')]: {
							flexWrap: 'wrap'
						},
						'& .font-size-17': {
							fontWeight: '400', 
							fontFamily: 'Lato, sans-serif',
							fontSize: '17px'
						}
					}
				}
			},
			'& .contact': {
				margin: '3% 0', 
				color: 'white',
				display: 'flex', 
				justifyContent: 'space-evenly',
				padding: '10px',
				alignItems: 'center',
				fontFamily: 'Lato, sans-serif',
				fontSize: '16px',
				fontWeight: '400',
				[theme.breakpoints.down('sm')] : {
					flexWrap: 'wrap'
				}
			}
		}
	},
	button: {
		backgroundColor: '#f9bf25 !important',
		color: 'black',
		height: '40px',
		width: '180px',
		fontWeight: 'bold',
		borderRadius: 'unset'
	},
	detailsBody: {
		display: 'flex',
		padding: '30px',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap'
		}
	},

	dateNumber: {
		fontSize: '50px', 
		marginRight: '35px',
		color: 'gray',
		[theme.breakpoints.down('sm')]:{
			display: 'flex',
			alignItems:  'center'
		},
	},
	number: {
		fontWeight: '900', 
		fontFamily: 'Lato, sans-serif', 
		fontSize: '60px', 
		margin: 'unset'
	},
	text: {
		fontWeight: '400', 
		fontFamily: 'Lato, sans-serif', 
		fontSize: '48px', 
		margin: 'unset', 
		textTransform: 'uppercase'
	},
	carouselStyle: {
		height: '30vh',
		'& ul': {
			display: 'flex',
			alignItems: 'center',
			'& li': {
				margin: '0 10px 0 0'
			}
		}
	},
	filesContent: {
		display: 'flex',
		flexWrap: 'wrap'
	},

	sectionTitle: {
		textAlign: 'left',
		color: '#404040',
		fontSize: '20px', 
		fontWeight: 'bold', 
		fontFamily: 'Lato, sans-serif'
	},
	registerUrl: {
		textDecoration: 'none',
		fontFamily: 'Lato, sans-serif',
		fontSize: '17px',
		fontWeight: '700'
	}
})

export default withStyles(styleSheet)(EventDetails)